<script setup>
import { useDark } from '@vueuse/core';

const isDark = useDark();
</script>

<template>
    <div
        class="page min-h-screen bg-gray-100 dark:bg-gray-900"
        :class="{ 'dark-app': isDark }"
    >
        <main>
            <slot />
        </main>
    </div>
</template>
